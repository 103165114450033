//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeCard',
  props: {
    animation: {
      type: Boolean,
      default: false,
    },
  },
};
