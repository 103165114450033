//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable @typescript-eslint/no-var-requires */
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import { getPortProduct, getPortSkuList } from '@/api/home';
import { ERROR_OK } from '@/api/config';
import Buy from '../Buy/index.vue';

// const netflix = require('@/assets/logo1.png');

export default Vue.extend({
  name: 'ProductCart',
  components: { Buy },
  props: {
    name: {
      type: String,
      default: '',
    },
    fromLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // netflix,
      showBuy: false,
      currentSku: {},
      buyParams: {},
      productList: [],
      buyText: '',
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapState({
      hasLogin: (state) => state.user.token,
      product: (state) => state.user.product,
      pageRoute: (state) => state.page.pageRoute,
    }),
  },
  methods: {
    ...mapActions({
      changeProduct: 'user/changeProduct',
    }),
    handleShowBuy(car) {
      const { id, selectableProductType } = car;
      // 未登陆存下商品参数，登录后在商品页面取
      if (!this.hasLogin && ['Products', 'Home'].includes(this.name)) {
        this.changeProduct({ id, selectableProductType });
      } else {
        this.changeProduct('');
      }
      this.handleGetPortSkuList({ id, selectableProductType });
    },
    handleGetPortSkuList(data) {
      const { id, selectableProductType } = data;
      getPortSkuList({
        categoryId: id,
        selectableProductType,
      }).then((res) => {
        if (res.code === ERROR_OK) {
          this.buyText = res.data.sku_page_btn;
          this.currentSku = this.formatterGoodSku(res.data);
          this.buyParams = { isRenewalBill: 0 };
          this.showBuy = true;
        }
      });
    },
    getData() {
      getPortProduct().then((res) => {
        if (res.code === ERROR_OK) {
          const productList = res.data || [];
          this.productList = productList.map((item) => {
            const { productDescription = '' } = item;
            return { ...item, desc: productDescription.split('|') };
          });
          // 如果当前页面是Products页面，并且是从登陆页面跳转过来
          // 如果存了商品信息，表示是首页点击商品后跳转登陆
          // 需要弹出商品购买弹窗，用来优化用户体验
          if (this.product && this.pageRoute.to.name === 'Products' && this.pageRoute.from.name === 'Login') {
            this.handleGetPortSkuList(this.product);
          }
        }
      });
    },
    formatterGoodSku(data) {
      const {
        monthNumSet, sku_page_p1: kName, /* screensSet, */ skuList,
      } = data;
      const tree = [
        {
          k: kName,
          k_id: '1',
          k_s: 'monthNum',
          v: monthNumSet.map((item, index) => ({
            id: item,
            name: skuList[index].skuMonthName,
            imgUrl: skuList[index].skuImg,
          })),
        },
      ];
      const list = skuList.map((item) => {
        const {
          screens, monthNum, originalPrice, id, actualPrice, discount,
        } = item;
        return {
          id,
          screens,
          monthNum,
          price: actualPrice,
          stock_num: 110,
          discount,
          originalPrice,
        };
      });
      return { tree, list };
    },
  },
});
